<template>
    <div class="page_content">
        <div class="page_detail">
            <a-divider orientation="left">酒店信息</a-divider>
            <div class="li_item">
                <a-descriptions :column="2">
                    <a-descriptions-item label="酒店ID">
                        {{detail.hotel_id}}
                    </a-descriptions-item>
                    <a-descriptions-item label="酒店名称">
                        {{detail.hotel_name}}
                    </a-descriptions-item>
                    <a-descriptions-item label="所在城市">
                        {{detail.province+detail.city}}
                    </a-descriptions-item>
                    <a-descriptions-item label="酒店地址">
                        {{detail.province+detail.city+detail.address}}
                    </a-descriptions-item>
                    <a-descriptions-item label="联系电话">
                        {{detail.phone || '暂无'}}
                    </a-descriptions-item>
                    <a-descriptions-item label="营业状态">
                        {{detail.status_name}}
                    </a-descriptions-item>
                </a-descriptions>
            </div>
            <a-divider orientation="left">房型信息</a-divider>
            <div class="li_item">
                <div class="arpproval_title line-height">
                    <span class="department_appr">房型ID</span>
                    <span class="department_appr">房间名称</span>
                    <span>床数</span>
                    <span class="department_appr">房间属性</span>
                    <span style="width:9%">房态</span>
                    <span class="goods_id">价格/晚</span>
                </div>
                <div class="arpproval_title1" v-for="room in detail.rooms_data" :key='room.goods_id'>
                    <span class="department_appr">
                        {{room.goods_id}}
                    </span>
                    <span class="department_appr">{{room.room_name}}</span>
                    <span>{{room.bed_count}}</span>
                    <span class="department_appr"> {{room.extend_info}}</span>
                    <span style="width:9%">{{room.status==1?'可预订':'不可预定'}}</span>
                    <span class="goods_id">{{room.sale_price}}元</span>
                </div>
            </div>
            <div class="box_form box_form_btns">
                <a-button type="defalut" @click="$router.back()">返回</a-button>
            </div>
        </div>
    </div>
</template>

<script>
    import LookImages from "@/components/LookImages";
    import {
        getHotelDetail
    } from '@/api/evection.js'
    import Avatar from "@/components/small/Avatar";
    export default {
        components: {
            LookImages,
            Avatar
        },
        data() {
            return {
                detail: {
                    approval_data: {},
                    rooms_data: {}
                }
            }
        },
        created() {
            this.get_info()
        },
        methods: {
            to_contract() {
                this.$router.push('contract_detail?id=' + this.detail.contract_id)
            },
            get_info() {
                getHotelDetail({
                    data: {
                        id: this.$route.query.id,
                        city: this.$route.query.city,
                    }

                }).then(res => {
                    this.detail = res.data.list
                })
            },
        }
    }
</script>

<style lang="less" scoped>
    .time_line {
        max-width: 600px;
    }

    .avatar_ul {
        display: flex;
        flex-wrap: wrap;

        .avatar {
            margin: 16px 10px 0;
            width: 80px;
        }
    }

    .page_detail {
        margin: 0;
        margin-left: 5%;

    }

    .li_item {
        .title_item {
            width: 80px;
            text-align: left;
            display: inline-block;
            color: #000;
        }

        margin-bottom: 30px;
    }

    .arpproval_title {
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: rgba(245, 245, 245, 1);

        span {
            width: 6%;
            display: inline-block;

            height: 48px;
        }

        .department_appr {
            width: 25%;
        }

        .goods_id {
            width: 10%;
        }
    }

    .arpproval_title1 {
        background: none;
        line-height: normal;
        border: 1px solid rgba(245, 245, 245, 1);
        text-align: center;
        display: flex;

        .goods_id {
            width: 13%;
        }

        .department_appr {
            width: 25%;
        }

        .goods_id {
            width: 10%;
        }

        span {
            width: 6%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px ;
            box-sizing: border-box;
            word-wrap: break-word;
        }
    }

    .go_see {
        color: #1890ff;
    }
</style>